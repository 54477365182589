<template>
    <v-container class="ma-0 pa-0">
        <v-row class="py-10 px-8">
            <strong>Filtros</strong>

            <div class="mt-4">
                Fecha de creación 
                <v-menu v-model="menuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="quotation.dateCreateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.dateCreateFrom" @input="menuFrom = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="quotation.dateCreateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.dateCreateTo" @input="menuTo = false"></v-date-picker>
                </v-menu>
            </div>

            <v-select @keydown.enter="filter()" v-model="quotation.company_id" :items="companyLists" item-text="name" item-value="id" attach chips label="Empresa" multiple>
            </v-select>
            <v-select @keydown.enter="filter()" v-model="quotation.contact_id" :items="contactLists" item-text="name" item-value="id" attach chips label="Contacto" multiple>
            </v-select>

            <v-select @keydown.enter="filter()" v-model="quotation.items" :items="itemsLists" item-text="name" item-value="id" attach chips label="Productos" multiple>
            </v-select>

            <v-text-field @keydown.enter="filter()" v-model="quotation.note" label="Notas"></v-text-field>

            <div class="mt-4">
                Última Actualización 
                <v-menu v-model="menuFrom2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="quotation.dateUpdateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.dateUpdateFrom" @input="menuFrom2 = false"></v-date-picker>
                </v-menu>

                <v-menu v-model="menuTo2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="quotation.dateUpdateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.dateUpdateTo" @input="menuTo2 = false"></v-date-picker>
                </v-menu>
            </div>

        </v-row>
        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary">
            <v-list-item link @click="filter()" dark>
                <v-list-item-content>
                    <strong>Filtrar (0)</strong>
                </v-list-item-content>
            </v-list-item>    
        </v-card>
    </v-container>
</template>
<script>
  export default {
    data: () => ({
        menuTo:false,
        menuTo2:false,
        menuFrom:false,
        menuFrom2:false,
        quotation:{
            company_id:'',
            contact_id:'',
            note:'',
            dateCreateFrom:'',
            dateCreateTo:'',
            dateUpdateFrom:'',
            dateUpdateTo:'',
            items:''
        },
    }),
    computed: {
        companyLists(){
            return this.$store.state.company.companies;
        },
        contactLists(){
            return this.$store.state.contact.contacts;
        }, 
        itemsLists(){
            return this.$store.state.item.items;
        }, 
    },
    created () {
        this.$store.dispatch('company/getCompanies')
        this.$store.dispatch('contact/getContacts')
        this.$store.dispatch('item/getItems')
    },
    methods: {
        filter(){
            this.$emit("filtersSellers", this.quotation);
        }
    }
  }
</script>