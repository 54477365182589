<template>
    <v-container style="max-width:100vw;" class="mb-6">
        <!-- Reporte de Ventas General -->
        <div style="background:white;">
            <!-- Header -->
            <v-toolbar class="px-6 py-4 mb-6" flat >
                <v-toolbar-title class="hidden-md-and-down">Reporte de Ventas</v-toolbar-title>
                <v-toolbar-title class="hidden-md-and-up">Ventas</v-toolbar-title>
                <v-btn icon>
                    <v-icon @click.stop="filters1 = !filters1">mdi-filter</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="como=='dia'" @click="pormes" class="ml-8">MES</v-btn>
                <v-btn v-else class="ml-8" @click="pordia">DIA</v-btn>
            </v-toolbar>
            <!-- Filters -->
            <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters1" :clipped="$vuetify.breakpoint.lgAndUp" app>
                <filterQuotations @filtersQuotation="filtersQuotation"/>
            </v-navigation-drawer>
            <!-- Grafica -->
            <apexchart type="area" height="500" :options="options" :series="options.series"></apexchart>
        </div>
        <div style="height:50px;"/><!-- Divider -->
        <!-- Reporte de Ventas por Vendedor -->
        <div style="background:white;">
            <!-- Header -->
            <v-toolbar class="px-6 py-4 mb-6" flat >
                <v-toolbar-title class="hidden-md-and-down">Reporte de Ventas por Vendedor</v-toolbar-title>
                <v-toolbar-title class="hidden-md-and-up">Vendedores</v-toolbar-title>
                <v-btn icon>
                    <v-icon @click.stop="filters2 = !filters2">mdi-filter</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="como=='dia'" @click="pormes" class="ml-8">MES</v-btn>
                <v-btn v-else class="ml-8" @click="pordia">DIA</v-btn>
            </v-toolbar>
            <!-- Filters -->
            <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters2" :clipped="$vuetify.breakpoint.lgAndUp" app>
                <filterSellers @filtersSellers="filtersSellers" />
            </v-navigation-drawer>
            <!-- Grafica -->
            <apexchart type="bar" height="440" :options="optionsVendedores" :series="optionsVendedores.vendedores"></apexchart>
        </div>
    </v-container>
</template>

<script>
import Filter from "../quotations/filter"
import FilterSellers from "../reports/filter"
export default {
    components: {
        'filterQuotations':Filter,
        'FilterSellers':FilterSellers,
    },
    data: function () {
        return {
            fechadeFilterValue:'',
            fechadeFilterValueHistorico:'',
            fechaaFilterValueHistorico:'',
            fechaaFilterValue:'',
            filters1: false,
            filters2: false,
            como:'dia',
            accumulator:0, 
            /* Reporte General */
            options: {
                xaxis: {
                    type: "datetime"
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return "$" + new Intl.NumberFormat("es-MX").format(value);
                        },
                    },
                },
                dataLabels: {
                    formatter: function (value) {
                        return "$" + new Intl.NumberFormat("es-MX").format(value);
                    },
                },
                colors: ['#369ff8', '#f9ba43'],
                series: [], 
            },
            /* Reporte Vendedores */
            optionsVendedores:{
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return "$" + new Intl.NumberFormat("es-MX").format(value);
                        },
                    },
                },
                dataLabels: {
                    formatter: function (value) {
                        return "$" + new Intl.NumberFormat("es-MX").format(value);
                    },
                },
                vendedores: [],
                plotOptions: {
                    bar: {
                        distributed: true,
                    }
                },
                colors: ['#785dd0', '#ff4560', '#ffb019', '#00e495', '#008ffa'],
                chart: {
                    type: 'area',
                },
            }
        }
    },
    mounted () {
        var date = new Date();
        var desde = new Date(date.getFullYear(), date.getMonth(), 1); 
        var hasta = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        desde = desde.toISOString().slice(0,10)
        hasta =  hasta.toISOString().slice(0,10)
        this.fechadeFilterValue = this.formatoFecha(desde)
        this.fechaaFilterValue = this.formatoFecha(hasta)
        this.fechadeFilterValueHistorico = this.inicioaniopasado(this.fechadeFilterValue)
        this.fechaaFilterValueHistorico = this.finaniopasado(this.fechaaFilterValue)
        this.options.series = [this.venta, this.pasado]
        this.optionsVendedores.vendedores = [this.ventasXvendedor]
        this.render()
   },
   computed: {
       /* Traer lo del año pasado */
        pasado(){
            var pasado = {
                name: 'Año Pasado',
                data: this.historico('cerrado'),
                type: 'area',
            }
            return pasado
        },
        /* Traer ventas reales */
        venta(){
            var ventas = {
                name: 'Ventas',
                data: this.traer('cerrado'),
                type: 'bar'
            }
            return ventas
        },
        /* Crear arreglo con toda la info de ventas */
        info(){
            var info = this.ventasLists.map(venta => {
                return {
                    x: new Date(venta.updated_at),
                    y: venta.amount,
                    estatus: venta.status
                }
            })
            return info
        },
        /* Crear arreglo con toda la info de ventas pasadas */
        infoHistorico(){
            var info = this.historicoLists.map(venta => {
                return {
                    x: new Date(venta.updated_at),
                    y: venta.amount,
                    estatus: venta.status
                }
            })
            return info
        },
        /* Grafica Ventas por Vendedor */
        ventasXvendedor(){
            var ventavendedor = this.ventasLists
            .map(venta => {
                return {
                    x: venta.user_id,
                    y: venta.amount,
                }
            })
            var obj = ventavendedor.sort(function(b,a){
                return b.x - a.x;
            });
            var holder = {};
            obj.forEach(function(d) {
                if (holder.hasOwnProperty(d.x)) {
                    holder[d.x] = holder[d.x] + d.y;
                } else {
                    holder[d.x] = d.y;
                }
            });
            var obj2 = [];
            for (var prop in holder) {
                obj2.push({ x: prop, y: holder[prop] });
            }
            var perro = obj2.map(venta => {
                return {
                    x: this.vendedor(venta.x),
                    y: venta.y,
                }
            })
            var resultado = {
                data: perro,
            }
            return resultado
        },
        historicoLists() {
            this.gato = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido')
            return this.gato
        },
        /* lista de ventas DB con filtros */
        ventasLists() {
            var perro = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido')
            return perro
        },
        /* lista de usuarios */
        userLists() {
            return this.$store.state.user.users;
        }
   },
   methods:{
        removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject  = {};
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        },
        filtersQuotation: function(params) {
            var filterQuotation = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido')
            if(params.company_id!=''&&params.company_id!=undefined&&params.company_id!=null){
                var uno = filterQuotation.filter(quotation=>quotation.company_id == params.company_id[0])
                for(var i=1; i<params.company_id.length; i++){
                    uno=uno.concat(filterQuotation.filter(quotation=>quotation.company_id == params.company_id[i]))   
                }
                filterQuotation = this.removeDuplicates(uno, "id");
            }
            if(params.contact_id!=''&&params.contact_id!=undefined&&params.contact_id!=null){
                var dos = filterQuotation.filter(quotation=>quotation.contact_id == params.contact_id[0])
                for(var i=1; i<params.contact_id.length; i++){
                    dos=dos.concat(filterQuotation.filter(quotation=>quotation.contact_id == params.contact_id[i]))   
                }
                filterQuotation = this.removeDuplicates(dos, "id");
            }
            if(params.user_id!=''&&params.user_id!=undefined&&params.user_id!=null){
                var tres = filterQuotation.filter(quotation=>quotation.user_id == params.user_id[0])
                for(var i=1; i<params.user_id.length; i++){
                    tres=tres.concat(filterQuotation.filter(quotation=>quotation.user_id == params.user_id[i]))   
                }
                filterQuotation = this.removeDuplicates(tres, "id");
            }
            if(params.items!=''&&params.items!=undefined&&params.items!=null){
                var cuatro = filterQuotation.filter(quotation=>this.check(quotation.items, params.items[0]) == 'contain')
                for(var i=1; i<params.items.length; i++){
                    cuatro=cuatro.concat(filterQuotation.filter(quotation=>this.check(quotation.items, params.items[0]) == 'contain'))   
                }
                filterQuotation = this.removeDuplicates(cuatro, "id");
            }
            /* text fields */
            if(params.note!=''){
                filterQuotation=filterQuotation.filter(quotation=>quotation.note.toLowerCase().includes(params.note.toLowerCase()))
            }
            this.quotations = filterQuotation.map(id=>{
                return{
                    id:id.id,
                    company:this.company(id.company_id),
                    contact:this.contact(id.contact_id),
                    salesman:this.salesman(id.user_id),
                    amount:id.amount,
                    pdf:id.pdf,
                    note:id.note,
                    items:id.items,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });
        },
        filtersSellers: function(params) {
            var filterQuotation = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido')
            if(params.company_id!=''&&params.company_id!=undefined&&params.company_id!=null){
                var uno = filterQuotation.filter(quotation=>quotation.company_id == params.company_id[0])
                for(var i=1; i<params.company_id.length; i++){
                    uno=uno.concat(filterQuotation.filter(quotation=>quotation.company_id == params.company_id[i]))   
                }
                filterQuotation = this.removeDuplicates(uno, "id");
            }
            if(params.contact_id!=''&&params.contact_id!=undefined&&params.contact_id!=null){
                var dos = filterQuotation.filter(quotation=>quotation.contact_id == params.contact_id[0])
                for(var i=1; i<params.contact_id.length; i++){
                    dos=dos.concat(filterQuotation.filter(quotation=>quotation.contact_id == params.contact_id[i]))   
                }
                filterQuotation = this.removeDuplicates(dos, "id");
            }
            if(params.items!=''&&params.items!=undefined&&params.items!=null){
                var cuatro = filterQuotation.filter(quotation=>this.check(quotation.items, params.items[0]) == 'contain')
                for(var i=1; i<params.items.length; i++){
                    cuatro=cuatro.concat(filterQuotation.filter(quotation=>this.check(quotation.items, params.items[0]) == 'contain'))   
                }
                filterQuotation = this.removeDuplicates(cuatro, "id");
            }
            /* text fields */
            if(params.note!=''){
                filterQuotation=filterQuotation.filter(quotation=>quotation.note.toLowerCase().includes(params.note.toLowerCase()))
            }
            this.quotations = filterQuotation.map(id=>{
                return{
                    id:id.id,
                    company:this.company(id.company_id),
                    contact:this.contact(id.contact_id),
                    amount:id.amount,
                    pdf:id.pdf,
                    note:id.note,
                    items:id.items,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });
        },
        check(items, param){
            for(var i=0; i<items.length; i++){
                  if(items[i].item == param){
                      return 'contain'
                  }
            }  
        },

        estatusVendedor(vendedor){
            return this.userLists.filter(user => user.id == vendedor).map(user => user.estatus)[0];
        },
        formatoFecha(perro){
                var d = perro.toString().slice(8,10)
                var m = perro.toString().slice(5,7)
                var a = perro.toString().slice(0,4)
                var fecha = m+'-'+d+'-'+a
                var fecha=new Date(fecha)
                return fecha
        },
        inicioaniopasado(perro){
            const fill = (number, len) => "0".repeat(len - number.toString().length) + number.toString();
            var j = new Date(perro);
            var o = j.getFullYear();
            var e = j.getMonth();
            var l = j.getDate();
            var dateh = new Date(o - 1, e, l);
            this.fechadeFilterValueHistorico =  
                new Date(dateh.getFullYear(), dateh.getMonth(), dateh.getDate()); 
            dateh = fill(this.fechadeFilterValueHistorico.getMonth()+1, 2)+'-'+fill(this.fechadeFilterValueHistorico.getDate(),2)+'-'+this.fechadeFilterValueHistorico.getFullYear()
            return dateh
        },
        finaniopasado(perro){
            const fill = (number, len) => "0".repeat(len - number.toString().length) + number.toString();
            var so = new Date(perro);
            var fi = so.getFullYear();
            var an = so.getMonth();
            var aa = so.getDate();
            var datehs = new Date(fi - 1, an, aa);
            this.fechaaFilterValueHistorico =  
                new Date(datehs.getFullYear(), datehs.getMonth(), datehs.getDate()); 
            datehs = fill(this.fechaaFilterValueHistorico.getMonth()+1, 2)+'-'+fill(this.fechaaFilterValueHistorico.getDate(), 2)+'-'+this.fechaaFilterValueHistorico.getFullYear()
            return datehs
        },
        /* Nombre de vendedor */
        vendedor(id) {
            return this.userLists.filter(user => user.id == id).map(user => user.name)[0];
        },
        /* Total de ventas por estatus */
        total(item){
            var total = this.ventasLists
            if(total!=undefined&&total!=0){
                total = total
                .filter(venta => venta.estatus == item)
                .reduce((a, b) => a + (b['valor'] || 0), 0)
                return total
            }else{
                return 0.00
            }
        },
        /* Botón acomodo por mes */
        pormes(){
            this.como='mes'
            this.render()
        },
        /* Botón acomodo por dia */
        pordia(){
            this.como='dia'
            this.render()
        },
        /* cargar info */
        render(){
            this.options.series = [this.venta, this.pasado,]
            this.optionsVendedores.vendedores = [this.ventasXvendedor]
        },
        /* Informacion del historico */
        historico(){
            /* Render por dia */
            if(this.como=='dia'){
                var obj = this.infoHistorico.sort(function(b,a){
                    return new Date(b.x) - new Date(a.x);
                });
                var holder = {};
                obj.forEach(function(d) {
                    if (holder.hasOwnProperty(d.x.toISOString().slice(0,10))) {
                        holder[d.x.toISOString().slice(0,10)] = holder[d.x.toISOString().slice(0,10)] + d.y;
                    } else {
                        holder[d.x.toISOString().slice(0,10)] = d.y;
                    }
                });
                var obj2 = [];
                for (var prop in holder) {
                    obj2.push({ x: prop, y: holder[prop] });
                }
                var acumulado = obj2
                acumulado.map(i => {
                    i.y=i.y + this.accumulator
                    this.accumulator = i.y

                    var d = new Date(i.x)
                    var year = d.getFullYear();
                    var month = d.getMonth();
                    var day = d.getDate();
                    var c = new Date(year + 1, month, day);

                    i.x = new Date(c).toISOString().slice(0,10)
                })
                this.accumulator=0
                return acumulado
            }
            /* Render por mes */
            else{
                var obj = this.infoHistorico.sort(function(b,a){
                    return new Date(b.x) - new Date(a.x);
                });
                var holder = {};
                obj.forEach(function(d) {
                    if (holder.hasOwnProperty(d.x.toISOString().slice(0,8))) {
                        holder[d.x.toISOString().slice(0,8)] = holder[d.x.toISOString().slice(0,8)] + d.y;
                    } else {
                        holder[d.x.toISOString().slice(0,8)] = d.y;
                    }
                });
                var obj2 = [];
                for (var prop in holder) {
                    obj2.push({ x: prop, y: holder[prop] });
                }
                var acumulado = obj2
                acumulado.map(i => {
                    i.y=i.y + this.accumulator
                    this.accumulator = i.y

                    var d = new Date(i.x)
                    var year = d.getFullYear();
                    var month = d.getMonth();
                    var c = new Date(year + 1, month);

                    i.x = new Date(c).toISOString().slice(0,8)
                })
                this.accumulator=0
                return acumulado
            }
        },
        /* Informacion para las graficas */
        traer(){
            /* Render por dia */
            if(this.como=='dia'){
                var obj = this.info.sort(function(b,a){
                    return new Date(b.x) - new Date(a.x);
                });
                var holder = {};
                obj.forEach(function(d) {
                    if (holder.hasOwnProperty(d.x.toISOString().slice(0,10))) {
                        holder[d.x.toISOString().slice(0,10)] = holder[d.x.toISOString().slice(0,10)] + d.y;
                    } else {
                        holder[d.x.toISOString().slice(0,10)] = d.y;
                    }
                });
                var obj2 = [];
                for (var prop in holder) {
                    obj2.push({ x: prop, y: holder[prop] });
                }
                var acumulado = obj2
                acumulado.map(i => {
                    i.y=i.y + this.accumulator
                    this.accumulator = i.y
                    i.x = new Date(i.x).toISOString().slice(0,10)
                })
                this.accumulator=0
                return acumulado
            }
            /* Render por mes */
            else{
                var obj = this.info.filter(info => info.estatus == estatus).sort(function(b,a){
                    return new Date(b.x) - new Date(a.x);
                });
                var holder = {};
                obj.forEach(function(d) {
                    if (holder.hasOwnProperty(d.x.toISOString().slice(0,8))) {
                        holder[d.x.toISOString().slice(0,8)] = holder[d.x.toISOString().slice(0,8)] + d.y;
                    } else {
                        holder[d.x.toISOString().slice(0,8)] = d.y;
                    }
                });
                var obj2 = [];
                for (var prop in holder) {
                    obj2.push({ x: prop, y: holder[prop] });
                }
                var acumulado = obj2
                acumulado.map(i => {
                    i.y=i.y + this.accumulator
                    this.accumulator = i.y
                    i.x = new Date(i.x).toISOString().slice(0,8)
                })
                this.accumulator=0
                return acumulado
            }
        },
   },
    created(){
        this.$store.dispatch('quotation/getQuotations')
        this.$store.dispatch('user/getUsers')
    },
}
</script>
<style>
.apexcharts-toolbar{
    display:none!important;
}
</style>